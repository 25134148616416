import React, { useEffect, useState } from "react";
import "./tv.scss";
import { Link } from "react-router-dom";
import Pages from "../links/links";
import LazyLoad from "react-lazy-load";

const gifs = {
  "all projects": "https://i.imgur.com/ladLG8s.mp4",
  Branded: "https://i.imgur.com/v148Rq1.mp4",
  "digital experiences": "https://i.imgur.com/xkHll3g.mp4",
  "digital strategy": "https://i.imgur.com/9KSbY6c.mp4",
  "web development": "https://i.imgur.com/LlWt2E3.mp4",
  Design: "https://i.imgur.com/XhFaSNX.mp4",
};

const preloadVideos = () => {
  Object.values(gifs).forEach((url) => {
    const video = document.createElement("video");
    video.src = url;
    video.preload = "auto";
  });
};

function Tv() {
  const [img, setImg] = useState(gifs["all projects"]);
  const [text, setText] = useState("");
  const [style, setStyle] = useState({ display: "none" });

  useEffect(() => {
    preloadVideos();
  }, []);

  const handleHover = (key) => {
    setStyle({ display: "block" });
    setImg(gifs[key]);
    setText(key);
  };

  const handleHoverLeave = () => {
    setStyle({ display: "none" });
    setImg(gifs["all projects"]);
    setText("all projects");
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 920px)");
    setIsMobile(mediaQuery.matches);
    // Add a listener to re-check the media query when the viewport changes
    const onChange = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", onChange);

    // Remove the listener when the component unmounts
    return () => mediaQuery.removeEventListener("change", onChange);
  }, []);

  return (
    <div>
      <div>
        {isMobile ? (
          <div className="ps mt-20">
            <span className="ps1 flex flex-row">
              <h1 className="text-left" id="ps">
                {" "}
                <span className="text-orange-600">“</span> we create{" "}
                <span className="text-orange-600"> Branded </span>
                <br />
                <span>digital experiences</span>
                <br />
                <span>digital strategy</span> <br />
                <span>web development</span> <br />
                <div className="flex items-center">
                  <span>Design</span>
                  &nbsp;
                  <span className="text-orange-600"> "</span>{" "}
                  <Link to={Pages.work} className="mx-3">
                    <div className="work-btn Dolce view-all rounded-full border border-white text-white text-xs hover:bg-orange-600 transition duration-500 ease-out hover:ease-in">
                      view all projects
                    </div>
                  </Link>
                </div>
              </h1>
            </span>
          </div>
        ) : (
          <div className="ps mt-20">
            <span className="ps1 flex flex-row">
              <h1 className="text-left" id="ps">
                <span className="text-orange-600">“</span> we create{" "}
                <span
                  className="text-orange-600"
                  onMouseEnter={() => handleHover("Branded")}
                  onMouseLeave={handleHoverLeave}
                >
                  Branded
                </span>
                <br />
                <span
                  onMouseEnter={() => handleHover("digital experiences")}
                  onMouseLeave={handleHoverLeave}
                >
                  digital experiences
                </span>
                <br />
                <span
                  onMouseEnter={() => handleHover("digital strategy")}
                  onMouseLeave={handleHoverLeave}
                >
                  digital strategy
                </span>{" "}
                <br />
                <span
                  onMouseEnter={() => handleHover("web development")}
                  onMouseLeave={handleHoverLeave}
                >
                  web development
                </span>{" "}
                <br />
                <div className="flex items-center">
                  <span
                    onMouseEnter={() => handleHover("Design")}
                    onMouseLeave={handleHoverLeave}
                  >
                    Design
                  </span>
                  &nbsp;
                  <span className="text-orange-600"> "</span>{" "}
                  <Link to={Pages.work} className="mx-3">
                    <div
                      className="work-btn Dolce view-all rounded-full border border-white text-white text-xs hover:bg-orange-600 transition duration-500 ease-out hover:ease-in"
                      onMouseEnter={() => handleHover("all projects")}
                      onMouseLeave={handleHoverLeave}
                    >
                      view all projects
                    </div>
                  </Link>
                </div>
              </h1>
            </span>
            <div className="tv-fx flex justify-center items-center ">
              <div
                style={style}
                className="work-tv mix-blend-difference z-10 absolute text-[#ffff]"
              >
                {text}
              </div>
              <LazyLoad offset={500} height={"100%"} width={"100%"}>
                <video
                  className="static z-0 border border-white"
                  disablePictureInPicture
                  loop
                  muted
                  playsInline
                  autoPlay
                  src={img}
                />
              </LazyLoad>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Tv;
