import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./contact.scss";
import Footer from "../../components/footer/footer";
import axios from "axios";

const blackBox = {
  initial: {
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
  },
  animate: {
    width: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    width: "100vw",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const ContactPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 920px)");
    setIsMobile(mediaQuery.matches);
    // Add a listener to re-check the media query when the viewport changes
    const onChange = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", onChange);

    // Remove the listener when the component unmounts
    return () => mediaQuery.removeEventListener("change", onChange);
  }, []);

  const [submitStatus, setSubmitStatus] = useState("Submit");

  return (
    <div>
      <motion.div
        className="fixed z-50 w-full h-full bg-orange-600"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={blackBox}
      ></motion.div>
      <div className="Dolce bg-off overflow-x-hidden" id="contact">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.5 }}
          className="text-white mix-blend-difference text-9xl  pt-32 mb-12 mx-12 flex flex-col gap-8 dont"
        >
          <span>
            Don't be a<br />
            stranger, <br />
            Let's Get <br />
            In Touch
          </span>
          {isMobile ? (
            <div className="flex flex-col gap-4">
              <span className="text-sm w-80 whether">
                {" "}
                Whether you want to colloborate on your next project, have any
                questions about us, or simply just want to say hi, We'd love to
                hear from you ♡
              </span>
              <div className="flex flex-row gap-4">
                <span className="text-sm w-80">
                  {" "}
                  If You want to book a zoom meeting to discuss further details,
                  Kindly provide your preferred day and time, and we'll arrange
                  the call accordingly.
                </span>
                <div className="flex flex-col gap-4 w-80">
                  <span className="text-sm opacity-75 break-all">
                    <span>20+ 1127723698</span> <br />
                    <a
                      href="mailto:info@flawless.productions"
                      aria-label="link to email"
                    >
                      info@flawless.productions
                    </a>
                  </span>
                  <span className="text-sm opacity-75">
                    <a
                      href="https://www.linkedin.com/company/flawlessproductions"
                      aria-label="link to linkedin"
                    >
                      linkedin
                    </a>{" "}
                    <br />
                    <a
                      href="https://www.instagram.com/flawlessproductions.agency/"
                      aria-label="link to instagram"
                    >
                      instagram
                    </a>{" "}
                    <br />
                    <a
                      href="https://twitter.com/Flawless_eg"
                      aria-label="link to twitter"
                    >
                      twitter
                    </a>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-row justify-between">
              <div className="flex gap-12">
                <span className="text-sm w-80">
                  {" "}
                  Whether you want to colloborate on your next project, have any
                  questions about us, or simply just want to say hi, We'd love
                  to hear from you ♡
                </span>
                <span className="text-sm w-80">
                  {" "}
                  If You want to book a zoom meeting to discuss further details,
                  Click{" "}
                  <a
                    className="underline"
                    href="https://calendly.com/flawless-productions/30min"
                  >
                    here
                  </a>{" "}
                  and chose the best time for you.
                </span>
              </div>
              <div className="flex gap-12">
                <span className="text-sm opacity-75">
                  <span>20+ 1127723698</span> <br />
                  <a href="mailto:info@flawless.productions">
                    info@flawless.productions
                  </a>
                </span>
                <span className="text-sm opacity-75">
                  <a href="https://www.linkedin.com/company/flawlessproductions">
                    linkedin
                  </a>{" "}
                  <br />
                  <a href="https://www.instagram.com/flawlessproductions.agency/">
                    instagram
                  </a>{" "}
                  <br />
                  <a href="https://twitter.com/Flawless_eg">twitter</a>
                </span>
              </div>
            </div>
          )}
        </motion.h1>
        <Formik
          initialValues={{
            email: "",
            name: "",
            phone: "",
            company: "",
            message: "",
          }}
          validate={(values) => {
            const errors: {
              email?: string;
              name?: string;
              company?: string;
              message?: string;
            } = {};
            if (!values.email) {
              errors.email = "*Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "*Invalid email address";
            }
            if (!values.name) {
              errors.name = "*Name is required";
            }
            if (!values.message) {
              errors.message = "*Message is required";
            }
            if (!values.company) {
              errors.company = "*Company Name is required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            setSubmitStatus("Submitting...");

            try {
              axios({
                method: "POST",
                url: "https://formbold.com/s/3K7qZ",
                data: values,
              });
              setSubmitStatus("Submitted Successfully");
              resetForm();
            } catch (error) {
              console.error(error);
              setSubmitStatus("Submit Failed");
            }

            setTimeout(() => {
              setSubmitStatus("submit");
            }, 5000);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="pt-8">
              <div className="mx-12 text-white mix-blend-difference">
                <div className="mb-12 flex flex-row items-center border-b-2 border-white">
                  <label className="block  text-2xl" htmlFor="email">
                    Email
                  </label>
                  <Field
                    className="feild-area autofill-bg text-2xl appearance-none  leading-tight focus:outline-none"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Please Enter Your Email"
                  />
                  <ErrorMessage
                    component="div"
                    name="email"
                    className=" text-sm mt-2 ml-auto"
                  />
                </div>

                <div className="mb-12 flex flex-row items-center border-b-2 border-white">
                  <label className="block  text-2xl" htmlFor="name">
                    Name
                  </label>
                  <Field
                    className="feild-area autofill-bg text-2xl appearance-none  leading-tight focus:outline-none"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Please Enter Your Name"
                  />
                  <ErrorMessage
                    component="div"
                    name="name"
                    className=" text-sm mt-2 ml-auto"
                  />
                </div>

                <div className="mb-12 flex flex-row items-center border-b-2 border-white">
                  <label className="block  text-2xl " htmlFor="phone">
                    Phone
                  </label>
                  <Field
                    className="feild-area autofill-bg text-2xl appearance-none  leading-tight focus:outline-none"
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Please Enter Your Number"
                  />
                  <span className=" text-sm mt-2 ml-auto">*optional</span>
                </div>

                <div className="mb-12 flex flex-row items-center border-b-2 border-white">
                  <label className="block  text-2xl" htmlFor="company">
                    Company
                  </label>
                  <Field
                    className="feild-area autofill-bg text-2xl appearance-none  leading-tight focus:outline-none"
                    type="text"
                    id="company"
                    name="company"
                    placeholder="Please Enter Your Company Name"
                  />
                  <ErrorMessage
                    component="div"
                    name="company"
                    className=" text-sm mt-2 ml-auto"
                  />
                </div>

                <div className="mb-12 flex flex-row border-b-2 border-white">
                  <label className="block  text-2xl" htmlFor="message">
                    Message
                  </label>
                  <Field
                    className="feild-area autofill-bg text-2xl appearance-none  leading-tight focus:outline-none "
                    as="textarea"
                    id="message"
                    name="message"
                    rows="1"
                    resize="none"
                    placeholder="Please Enter A Message"
                  />
                  <ErrorMessage
                    component="div"
                    name="message"
                    className=" text-sm mt-2 ml-auto"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className=" rounded w-full h-16 border-2 border-white submit-btn transition-all text-2xl"
                >
                  {submitStatus}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default ContactPage;
