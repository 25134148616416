import "./footer.scss";
import { useLocation, NavLink } from "react-router-dom";
import Pages from "../links/links";

function Footer() {
  const location = useLocation();

  return (
    <div
      className=" text-white-diff text-left flex flex-col mix-blend-difference"
      id="footer"
    >
      <div id="infinite z-10">
        <div id="infinite-fx" className="ticker-wrap">
          <span>FLAWLESS PRODUCTIONS</span>
          <span>FLAWLESS PRODUCTIONS</span>
        </div>
      </div>
      <div className="contact z-10">
        <ul>
          <li>
            <a
              href="https://www.linkedin.com/company/flawlessproductions"
              target="_blank"
              aria-label="link to linkedin"
              rel="noreferrer"
            >
              Linkedin
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/flawlessproductions.studio/"
              target="_blank"
              aria-label="link to instagram"
              rel="noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/Flawless_eg"
              target="_blank"
              aria-label="link to twitter"
              rel="noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.behance.net/flawlessproductions"
              target="_blank"
              aria-label="link to behance"
              rel="noreferrer"
            >
              Behance
            </a>
          </li>
          <li className=" copyright">
            <span className="text-xs">@2023 FLawless productions</span>
          </li>
        </ul>
        <ul>
          <li>
            <NavLink
              aria-label="link to work page where all work is displayed"
              className={` ${location.pathname === Pages.work ? "active" : ""}`}
              to={Pages.work}
            >
              {" "}
              WORK{" "}
            </NavLink>
          </li>
          <li>
            <NavLink
              aria-label="link to cabinet page where all concept projects are displayed"
              className={` ${
                location.pathname === Pages.cabinet ? "active" : ""
              }`}
              to={Pages.cabinet}
            >
              Cabinet
            </NavLink>
          </li>
          <li>
            <NavLink
              aria-label="link to studio page"
              className={` ${
                location.pathname === Pages.studio ? "active" : ""
              }`}
              to={Pages.studio}
            >
              STUDIO
            </NavLink>
          </li>
          <li>
            <NavLink
              aria-label="contact us, let's get in touch"
              className={` ${
                location.pathname === Pages.contact ? "active" : ""
              }`}
              to={Pages.contact}
            >
              CONTACT
            </NavLink>
          </li>
          <li className="copyright">
            <NavLink to={Pages.cookies} aria-label="cookies">
              <span className="text-xs invisible hidden">Cookies Policy</span>
            </NavLink>
          </li>
        </ul>
        <ul>
          <li className="text-xs">Let's collaborate</li>
          <li className="text-xs hover:opacity-50 ">
            <a
              href="mailto:info@flawless.productions"
              target="_blank"
              aria-label="link to email"
              rel="noreferrer"
            >
              info@flawless.productions
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
