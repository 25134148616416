import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Work from "../../pages/work/work";
import BlogPost from "../blog/blogPost";
import ContactPage from "../../pages/contact/contact";
import Studio from "../../pages/studio/studio";
import Home from "../../pages/home/Home";
import Cabinet from "../../pages/pantry/cabinet";
import Fest from "../../pages/pantry/fest/fest";
import Pages from "../links/links";
import Mk from "../../pages/work/mk/mk";
import Poppys from "../../pages/pantry/poppys/poppys";

const Main = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/work" element={<Work />}></Route>
        <Route path="/cabinet" element={<Cabinet />}></Route>
        <Route path={Pages.fest} element={<Fest />}></Route>
        <Route path={Pages.mk} element={<Mk />}></Route>
        <Route path={Pages.poppys} element={<Poppys />}></Route>
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/contact" element={<ContactPage />}></Route>
        <Route path="/studio" element={<Studio />}></Route>
      </Routes>
    </AnimatePresence>
  );
};

export default Main;
