import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import "./blog.scss";
import { motion } from "framer-motion";
import Pages from "../links/links";

const blackBox = {
  initial: {
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
  },
  animate: {
    height: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    height: "100vh",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

export default function BlogPost() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [post, setPost] = useState({
    title: "",
    content: "",
    featured_image: "",
  });
  const [nextPost, setNextPost] = useState({
    title: "",
    content: "",
    ID: 5,
    featured_image: "",
  });
  const [postIds, setPostIds] = useState([]);
  const [nextPostId, setNextPostId] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    // Fetch the current post
    axios
      .get(
        `https://public-api.wordpress.com/rest/v1/sites/flawlessproductions9.wordpress.com/posts/${id}`
      )
      .then((res) => {
        setPost(res.data);
      })
      .catch((error) => console.log(error));

    // Fetch a list of all post IDs
    axios
      .get(
        `https://public-api.wordpress.com/rest/v1/sites/flawlessproductions9.wordpress.com/posts`
      )
      .then((res) => {
        setPostIds(res.data.posts.map((post) => post.ID));
      })
      .catch((error) => console.log(error));
  }, [id]);

  useEffect(() => {
    // Fetch a random next post
    if (postIds.length > 0) {
      const filteredPostIds = postIds
        .map((postId) => postId.toString())
        .filter((postId) => postId !== id);

      const randomPostId =
        filteredPostIds[Math.floor(Math.random() * filteredPostIds.length)];

      if (randomPostId !== id) {
        setNextPostId(randomPostId);
        axios
          .get(
            `https://public-api.wordpress.com/rest/v1/sites/flawlessproductions9.wordpress.com/posts/${randomPostId}`
          )
          .then((res) => {
            setNextPost(res.data);
          })
          .catch((error) => console.log(error));
      }
    }
  }, [postIds, nextPostId, id]);

  const postContent =
    post && post.content ? (
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
    ) : (
      <div className="h-screen text-4xl Dolce">
        <span className="absolute top-48">Loading...</span>
      </div>
    );

  const opacity = {
    offscreen: {
      opacity: 0,
    },
    inView: (custom) => ({
      opacity: 1,
      transition: {
        duration: 1,
        delay: custom * 0.2,
        ease: "easeInOut",
      },
    }),
  };
  return (
    <div className="bg-white overflow-x-hidden">
      <motion.div
        className="fixed z-50 w-full bg-orange-600"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={blackBox}
      />
      <h1 className="text-left text-5xl font-medium pt-64 px-12">
        {post.title}
      </h1>
      {post.featured_image && (
        <img
          className="w-full py-5 px-12 h-img-blog object-cover object-center"
          src={post.featured_image}
          alt={post.title}
        />
      )}
      <div className="px-12 post-content1">{postContent}</div>
      <div className="px-12 Dolce pt-10">
        <motion.h1
          variants={opacity}
          custom={1}
          initial="offscreen"
          whileInView="inView"
          viewport={{ once: true }}
          className="text-5xl text-left"
        >
          Up next
        </motion.h1>
        <hr className="border-black border-1 my-2" />
        {nextPost && (
          <motion.div
            variants={opacity}
            custom={1}
            initial="offscreen"
            whileInView="inView"
            viewport={{ once: true }}
            className="text-5xl text-left h-96"
          >
            <Link
              to={`${Pages.blog}/${nextPost.ID}`}
              className="flex flex-col w-1/2 absolute right-0"
              aria-label={nextPost.title}
            >
              <img
                className="next-post-image"
                src={nextPost.featured_image}
                alt={nextPost.title}
              />
              <div className="next-post-info">
                <h3 className="text-right">{nextPost.title}</h3>
              </div>
            </Link>
          </motion.div>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
}
