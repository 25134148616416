import React, { useState, useEffect } from "react";
import "./navbar.scss";
import { motion } from "framer-motion";
import { useLocation, NavLink } from "react-router-dom";
import { ReactComponent as Menu } from "../../SVG/Asset 2.svg";
import Pages from "../links/links";
import { ReactComponent as LogoSvg } from "../../SVG/new logo.svg";

const Navbar = () => {
  const location = useLocation();
  const [showSideNav, setShowSideNav] = useState(false);

  const handleOpen = () => {
    setShowSideNav(true);
    document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setShowSideNav(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const buzzElements = document.querySelectorAll(".buzz");
    buzzElements.forEach((element) => {
      element.setAttribute("data-buzz", element.textContent);
    });
  }, []);

  return (
    <div>
      <div id="navbar">
        <motion.ul
          initial={{ opacity: 0, y: -100 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
              delay: 0.2,
              ease: "easeInOut",
            },
          }}
          viewport={{ once: true }}
        >
          <div className="nav-left flex flex-row items-baseline gap-32 w-full">
            <NavLink
              aria-label="home page"
              to={Pages.home}
              className={`nav-logo translate-y-[0.19rem]`}
              style={{ opacity: 1 }}
            >
              <LogoSvg />
            </NavLink>
            <NavLink
              aria-label="work page"
              to={Pages.work}
              className={`a-work ${
                location.pathname === Pages.work ? "active" : ""
              }`}
            >
              <li className="buzz">Work</li>
            </NavLink>
            <div className="ml-auto gap-32 flex flex-row items-baseline nav-right">
              <NavLink
                aria-label="Cabinet page"
                to={Pages.cabinet}
                className={`${
                  location.pathname === Pages.cabinet ? "active" : ""
                }`}
              >
                <li className="buzz">Cabinet</li>
              </NavLink>
              <NavLink
                aria-label="Studio page"
                to={Pages.studio}
                className={`${
                  location.pathname === Pages.studio ? "active" : ""
                }`}
              >
                <li className="buzz">Studio</li>
              </NavLink>
              <NavLink
                aria-label="contact page"
                to={Pages.contact}
                className={`${
                  location.pathname === Pages.contact ? "active" : ""
                }`}
              >
                <li className="buzz">Contact</li>
              </NavLink>
            </div>
            <div className="ml-auto nav-sideIcon" onClick={handleOpen}>
              <Menu className="side-menu"></Menu>
            </div>
          </div>
        </motion.ul>
      </div>
      {showSideNav && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{
            x: 0,
            transition: { ease: [0.87, 0, 0.13, 1], duration: 0.8 },
          }}
          className="side-nav"
        >
          <div className="z-0 absolute top-0 left-0 h-screen w-scree">
            <video
              className="w-screen h-screen object-cover"
              src="https://i.imgur.com/LlWt2E3.mp4"
              loop
              playsInline
              autoPlay
              muted
            />
          </div>
          <div
            className="nav-left flex flex-row items-baseline gap-32 w-full side-nav-logo z-50 "
            onClick={handleClose}
          >
            <NavLink
              aria-label="home page"
              to={Pages.home}
              className={`nav-logo`}
              style={{ opacity: 1 }}
            >
              <LogoSvg />
            </NavLink>
            <div className="ml-auto nav-sideIcon" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                className="side-menu"
                fill="#f6f4f3"
                viewBox="0 0 50 50"
              >
                <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
              </svg>
            </div>
          </div>
          <div className="flex justify-center w-screen items-center h-full relative z-40">
            <nav className="side-nav-content">
              <NavLink
                aria-label="work page"
                to={Pages.work}
                className="nav-link"
                onClick={handleClose}
              >
                WORK
              </NavLink>
              <NavLink
                aria-label="cabinet page"
                to={Pages.cabinet}
                className="nav-link"
                onClick={handleClose}
              >
                CABINET
              </NavLink>
              <NavLink
                aria-label="studio page"
                to={Pages.studio}
                className="nav-link"
                onClick={handleClose}
              >
                STUDIO
              </NavLink>
              <NavLink
                aria-label="contact page"
                to={Pages.contact}
                className="nav-link"
                onClick={handleClose}
              >
                CONTACT
              </NavLink>
            </nav>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Navbar;
