import React, { useRef, useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import "./work.scss";
import { ReactComponent as WorkSvg } from "../../SVG/work2.svg";
import anime from "animejs/lib/anime.es.js";
import { useInView } from "react-intersection-observer";
import mkVideo from "../../videos/mk.mp4";
import cabinet from "../../videos/cabinet.mp4";
import { Link } from "react-router-dom";
import Pages from "../links/links";
import CabinetPhoto from "../../photos/cabinet.png";

function useVideoControl(videoRef) {
  const handleMouseEnter = () => {
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    videoRef.current.pause();
  };

  return { handleMouseEnter, handleMouseLeave };
}

function WorkComp(this: any) {
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  const videoControl1 = useVideoControl(videoRef1);
  const videoControl2 = useVideoControl(videoRef2);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    // Add a listener to re-check the media query when the viewport changes
    const onChange = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", onChange);

    // Remove the listener when the component unmounts
    return () => mediaQuery.removeEventListener("change", onChange);
  }, []);

  const { ref, inView } = useInView({
    rootMargin: "10px 0px",
    triggerOnce: true,
  });

  const [scrolled, setScrolled] = React.useState(0);
  const [scrolledbar, setScrolledbar] = React.useState(0);

  const onScrollPage = () => {
    if (!inView) return;

    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolledPercentage = `${(scrolled / winHeightPx) * 100}%`;
    setScrolledbar(parseFloat(scrolledPercentage));
    setScrolled(document.documentElement.scrollTop);

    const trace = anime({
      targets: ".stline",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      delay: function (el, i) {
        return i * 250;
      },
      direction: "alternate",
      autoplay: false,
    });

    trace.seek((scrolledbar / 25) * trace.duration);
  };

  React.useEffect(() => {
    if (inView) {
      window.addEventListener("scroll", onScrollPage);
    }
    return () => {
      window.removeEventListener("scroll", onScrollPage);
    };
  });

  return (
    <div>
      <div className="work">
        {isMobile ? (
          <div>
            <div className="mix-blend-difference">
                <WorkSvg ref={ref}/>
                </div>
            <div className="Dolce  px-12 w-portfolio">
              <div className="work1 py-20">
                <Link
                  to={Pages.mk}
                  className="hs-wrapper"
                  aria-label="link to view our project for mk insurance broker"
                >
                  <LazyLoad
                    offset={500}
                    height={"100%"}
                    className="lazyload-container"
                  >
                    <video
                      disablePictureInPicture
                      loop
                      muted
                      playsInline
                      autoPlay
                      src={mkVideo}
                    ></video>
                  </LazyLoad>
                </Link>
                <div className="text-left float-left work-text">
                  <div className="flex flex-row items-center pt-2 gap-4">
                    <Link
                      aria-label="link to view our project for mk insurance broker"
                      to={Pages.mk}
                      className="rounded-full border border-black px-6 text-xs hover:bg-orange-600 hover:text-white transition duration-500 ease-out hover:ease-in"
                    >
                      view project
                    </Link>
                    <span className="text-xs">Social Media</span>
                  </div>
                  <h2 className="text-2xl">MK Insurance Broker</h2>
                </div>
              </div>
              <div className="work1 py-20">
                <Link
                  to={Pages.cabinet}
                  className="hs-wrapper ml-auto"
                  aria-label="link to view all our concept projects"
                >
                  <LazyLoad
                    offset={500}
                    height={"100%"}
                    className="lazyload-container"
                  >
                    <video
                      disablePictureInPicture
                      loop
                      muted
                      playsInline
                      autoPlay
                      src={cabinet}
                    ></video>
                  </LazyLoad>
                </Link>
                <div className="text-right float-right ml-auto work-text">
                  <div className="flex flex-row justify-center items-center pt-2 gap-4">
                    <Link
                      aria-label="link to view all our concept projects"
                      to={Pages.cabinet}
                      className="rounded-full border border-black px-6 text-xs hover:bg-orange-600 hover:text-white transition duration-500 ease-out hover:ease-in"
                    >
                      view cabinet
                    </Link>
                    <span className="text-xs">
                      innovative and imaginative concepts
                    </span>
                  </div>
                  <h2 className="text-2xl">cabinet</h2>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="w-work">
              <div className="w-svg">
                <WorkSvg className="svg-trace" ref={ref}></WorkSvg>
              </div>
            </div>
            <div className="Dolce  px-12 w-portfolio">
              <div className="work1 py-20">
                <Link
                  aria-label="link to view our project for Mk insurance broker"
                  to={Pages.mk}
                  className="hs-wrapper"
                  onMouseEnter={videoControl1.handleMouseEnter}
                  onMouseLeave={videoControl1.handleMouseLeave}
                >
                  <video
                    ref={videoRef1}
                    disablePictureInPicture
                    loop
                    muted
                    playsInline
                    src={mkVideo}
                    preload="none"
                    className="z-10 absolute object-cover"
                  ></video>
                  <div className="h-full pointer-events-none w-full z-0 absolute imagePlaceholder">
                    <img
                      src="https://i.imgur.com/HDRRQkc.png"
                      alt="semsem"
                      className="object-cover h-full w-full"
                    />
                  </div>
                </Link>
                <div className="text-left float-left work-text">
                  <div className="flex flex-row items-center pt-2 gap-4">
                    <Link
                      aria-label="link to view our project for mk insurance broker"
                      to={Pages.mk}
                      className="rounded-full border border-black px-6 text-xs hover:bg-orange-600 hover:text-white transition duration-500 ease-out hover:ease-in"
                    >
                      view project
                    </Link>
                    <span className="text-xs">Social Media</span>
                  </div>
                  <h2 className="text-2xl">MK Insurance Broker</h2>
                </div>
              </div>

              <div className="work1 py-20">
                <Link
                  aria-label="link to view all our concept projects"
                  to={Pages.cabinet}
                  className="hs-wrapper ml-auto"
                  onMouseEnter={videoControl2.handleMouseEnter}
                  onMouseLeave={videoControl2.handleMouseLeave}
                >
                  <video
                    ref={videoRef2}
                    disablePictureInPicture
                    loop
                    muted
                    playsInline
                    src={cabinet}
                    preload="none"
                    className="z-10 absolute object-cover"
                  ></video>
                  <div className="h-full pointer-events-none w-full z-0 absolute imagePlaceholder">
                    <img
                      src={CabinetPhoto}
                      alt="cabinet"
                      className="object-cover h-full w-full"
                    />
                  </div>
                </Link>
                <div className="text-right float-right ml-auto work-text">
                  <div className="flex flex-row justify-center items-center pt-2 gap-4">
                    <Link
                      aria-label="link to view all our concept projects"
                      to={Pages.cabinet}
                      className="rounded-full border border-black px-6 text-xs hover:bg-orange-600 hover:text-white transition duration-500 ease-out hover:ease-in"
                    >
                      view cabinet
                    </Link>
                    <span className="text-xs">
                      innovative and imaginative concepts
                    </span>
                  </div>
                  <h2 className="text-2xl">cabinet</h2>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default WorkComp;

