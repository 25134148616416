const Pages = {
  home: "/",
  work: "/work",
  studio: "/studio",
  contact: "/contact",
  cabinet: "/cabinet",
  fest: "/cabinet/fest",
  blog: "/blog",
  cookies: "/cookies",
  mk: "/work/mkinsurancebroker",
  poppys: "/cabinet/poppys",
};

export default Pages;
