import React, { useEffect, useState } from "react";
import "./work.scss";
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import Pages from "../../components/links/links";

const blackBox = {
  initial: {
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
  },
  animate: {
    width: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    width: "100vw",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const links = [
  {
    id: 1,
    link: Pages.mk,
    name: "MK Insurance Broker",
    year: 2023,
    image: "https://i.imgur.com/MnPrtU3.png",
  },
];

const Work = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [hoveredLink, setHoveredLink] = React.useState(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    // Add a listener to re-check the media query when the viewport changes
    const onChange = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", onChange);

    // Remove the listener when the component unmounts
    return () => mediaQuery.removeEventListener("change", onChange);
  }, []);

  return (
    <div className="bg-black overflow-x-hidden h-full">
      <motion.div
        className="fixed z-50 w-full bg-orange-600"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={blackBox}
      />
      <div className="Dolce px-12">
        <div className="h-screen flex text-white w-full items-end">
          <h1 className="text-left Dolce pb-4 text-[14rem] leading-none md:text-[10rem] sm:text-[8rem] xs:text[5rem] 2xs:text-[5rem]">
            Work
          </h1>
        </div>
      </div>
      {isMobile ? (
        <div className="grid ">
          <h1 className="px-12 pb-6 opacity-50 text-white">
            SELECTED PROJECTS:
          </h1>
          <hr className="border-white opacity-50" />
          {links.map((link) => (
            <div key={link.id} className="link Dolce">
              <Link
                to={link.link}
                className="relative block h-20 w-full text-white z-20 mix-blend-difference"
              >
                <div className="flex flex-row h-full justify-center items-center px-12 ">
                  <img
                    src={link.image}
                    alt={link.name}
                    className="w-24 pr-4 inline-block aspect-video"
                  />
                  <h1 className="text-xl"> {link.name} </h1>
                  <h1 className="ml-auto">{link.year}</h1>
                </div>
              </Link>
              <hr className="border-white" />
            </div>
          ))}
        </div>
      ) : (
        <div className="grid pt-16 text-white">
          <h1 className="px-12 pb-6 opacity-50">SELECTED PROJECTS:</h1>
          <hr className="border-white opacity-50" />
          {links.map((link) => (
            <div key={link.id} className="link Dolce">
              <Link
                to={link.link}
                onMouseEnter={() => setHoveredLink(link.id)}
                onMouseLeave={() => setHoveredLink(null)}
                style={{
                  opacity: hoveredLink === link.id ? 1 : 0.5,
                  zIndex: hoveredLink === link.id ? 20 : 0,
                }}
                className="relative block h-24 w-full text-black z-20 transition-all ease-in-out duration-200"
              >
                <div className="flex flex-row h-full justify-center items-center px-12 ">
                  <h1 className="text-xl text-white"> {link.name} </h1>
                  <div className="ml-auto text-[0.8rem] flex gap-2 items-center justify-center leading-3 text-white">
                    <h1>{link.year}</h1>
                  </div>
                </div>
                {hoveredLink === link.id && (
                  <motion.div
                    animate={{
                      rotate: [-1, 1, -1, 1, -1, 1, -1, 1, 0],
                    }}
                    transition={{ duration: 10, yoyo: Infinity }}
                    className="absolute top-[-100%] left-96 h-full w-full pointer-events-none z-0"
                  >
                    <motion.img
                      src={link.image}
                      alt={link.name}
                      className="pointer-events-none object-cover w-[25rem] h-[17.5rem]"
                      animate={{ opacity: 1, x: hoveredLink ? 30 : 0 }}
                      transition={{ duration: 0.3 }}
                    ></motion.img>
                  </motion.div>
                )}
              </Link>
              <hr
                className="border-white"
                style={{
                  opacity: hoveredLink === link.id ? 1 : 0.5,
                }}
              />
            </div>
          ))}
        </div>
      )}
      <Footer></Footer>
    </div>
  );
};

export default Work;
