import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Pages from "../../components/links/links";
import "./studio.scss";
import ThreeScene from "../../components/3dObject/3dObject";

const blackBox = {
  initial: {
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
  },
  animate: {
    width: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    width: "100vw",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const Studio: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const filePath = "/3d/fx.glb";

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 550px)");
    setIsMobile(mediaQuery.matches);
    // Add a listener to re-check the media query when the viewport changes
    const onChange = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", onChange);

    // Remove the listener when the component unmounts
    return () => mediaQuery.removeEventListener("change", onChange);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-black text-white overflow-x-hidden Dolce px-12 studio">
      <motion.div
        className="fixed z-50 w-full h-full bg-orange-600"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={blackBox}
      ></motion.div>
      {/* <div className="absolute top-12 right-10 z-0 w-fit">
        <div className="w-96 h-96">
          <ThreeScene filePath={filePath} />
        </div>
      </div> */}
      <div className="flex items-center justify-center py-32 z-10">
        <h1 className="say-goodbye custom-width">
          Say Goodbye to Ordinary and Hello to Flawless!
        </h1>
      </div>
      {isMobile ? (
        <div className="flex flex-col justify-center items-center py-8">
          <p className="pb-4 text-left custom-width connect">
            We connect brands to their target audience through our mastery of
            design and culture. Our team of experienced creatives and project
            managers specialize in branding, digital design, and digital
            marketing.
          </p>
          <div className="grid grid-row-3 custom-width gap-8 Dolce pt-5">
            <div className="branding w-screen flex flex-row items-start">
              <h2 className="text-xl pb-3">Brand Identity</h2>
              <ul className="opacity-50 ul-studio pl-16">
                <li>Logo Development</li>
                <li>Brand Strategy</li>
                <li>Corporate Identities</li>
                <li>Photography</li>
                <li>Illustration</li>
                <li>Typography</li>
              </ul>
            </div>
            <div className="digital w-screen flex flex-row items-start">
              <h2 className="text-xl pb-3">Digital Design</h2>
              <ul className="opacity-50 ul-studio pl-16">
                <li>UX Design</li>
                <li>UI Design</li>
                <li>Web Development</li>
                <li>Web Application</li>
                <li>Ecommerce Solutions</li>
              </ul>
            </div>
            <div className="marketing w-screen flex flex-row items-start">
              <h2 className="text-xl pb-3">Digital Marketing</h2>
              <ul className="opacity-50 ul-studio pl-16">
                <li>SEO Services</li>
                <li>SEM Services</li>
                <li>Social Media</li>
                <li>Copywriting</li>
                <li>Content Writing</li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center py-8">
          <p className="text-2xl md:text-xl sm:text-xl pb-4 text-left custom-width connect">
            We connect brands to their target audience through our mastery of
            design and culture. Our team of experienced creatives and project
            managers specialize in branding, digital design, and digital
            marketing.
          </p>
          <div className="grid grid-cols-3 custom-width justify-between Dolce pt-5">
            <div className="branding">
              <h2 className="text-xl pb-3">Brand Identity</h2>
              <ul className="opacity-50">
                <li>- Logo Development</li>
                <li>- Brand Strategy</li>
                <li>- Corporate Identities</li>
                <li>- Photography</li>
                <li>- Illustration</li>
                <li>- Typography</li>
              </ul>
            </div>
            <div className="digital">
              <h2 className="text-xl pb-3">Digital Design</h2>
              <ul className="opacity-50">
                <li>- UX Design</li>
                <li>- UI Design</li>
                <li>- Web Development</li>
                <li>- Web Application</li>
                <li>- Ecommerce Solutions</li>
              </ul>
            </div>
            <div className="marketing">
              <h2 className="text-xl pb-3">Digital Marketing</h2>
              <ul className="opacity-50">
                <li>- SEO Services</li>
                <li>- SEM Services</li>
                <li>- Social Media</li>
                <li>- Copywriting</li>
                <li>- Content Writing</li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className="w-fit text-right text-6xl ml-auto py-10 bridging">
        Bridging the <br /> Gap Between <br /> Brands and{" "}
        <motion.span
          initial={{ y: +100 }}
          whileInView={{ y: 0, transition: { duration: 3, delay: 0.5 } }}
          viewport={{ once: true }}
          className="block"
        >
          Consumers
        </motion.span>
      </div>
      {/* <div className="flex items-center justify-center">
        <div className="flex flex-col py-4 ">
          <div className="text-2xl">
            At Flawless Productions, we believe in the power of design to bring
            brands to life. Our focus is on delivering exceptional work while
            building lasting relationships with our clients. Join us as we craft
            brands and digital experiences that matter.
          </div>
        </div>
      </div> */}
      <div className="py-28">
        <div className="text-8xl ready">
          READY TO{" "}
          <Link to={Pages.contact} className="work-with-us">
            WORK WITH US ?
          </Link>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Studio;
