import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./3d.scss";

interface ThreeSceneProps {
  filePath: string;
}

const ThreeScene: React.FC<ThreeSceneProps> = ({ filePath }) => {
  const mountRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let scene: THREE.Scene;
    let camera: THREE.PerspectiveCamera;
    let renderer: THREE.WebGLRenderer;
    let model: THREE.Group;
    let light1: THREE.PointLight;
    let light2: THREE.PointLight;
    let environmentLight: THREE.AmbientLight;
    let rotationX = 0;
    let rotationY = 0;
    let rotationDirectionX = 1;
    let rotationDirectionY = 1;
    let rotationSpeed = 0.0005;
    let rotationLimit = Math.PI / 7;

    const init = () => {
      // Create scene
      scene = new THREE.Scene();

      // Create camera
      camera = new THREE.PerspectiveCamera(50, 1, 0.1, 2000);
      camera.position.set(0, 1000, 0); // Set camera position to the top
      camera.rotation.x = -Math.PI / 2; // Rotate the camera to look down from the top

      // Create renderer
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setClearColor(0x000000, 0);
      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current?.appendChild(renderer.domElement);

      // Add lights
      environmentLight = new THREE.AmbientLight(0xffffff, 10); // White environment light
      scene.add(environmentLight);

      light1 = new THREE.PointLight(0x808080, 5); // White point light 1
      light1.position.set(500, 500, 2000);
      scene.add(light1);

      light2 = new THREE.PointLight(0xb0b0b0, 5); // White point light 2
      light2.position.set(-500, 500, -2000);
      scene.add(light2);

      // Load .glb model
      const loader = new GLTFLoader();
      loader.load(filePath, (gltf) => {
        model = gltf.scene;

        // Apply dark grey color and more metallic materials to all meshes in the model
        const metallicMaterial = new THREE.MeshStandardMaterial({
          color: 0x9c9c9c, // White color
          roughness: 0.3, // Reduced roughness for more metallic effect
          metalness: 1.0, // Increased metalness
        });

        model.traverse((node) => {
          if (node instanceof THREE.Mesh) {
            node.material = metallicMaterial;
          }
        });

        scene.add(model);

        // Adjust camera aspect ratio based on the loaded model
        const box = new THREE.Box3().setFromObject(model);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());
        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = camera.fov * (Math.PI / 180);
        const cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));
        camera.position.set(center.x, center.y + cameraZ, center.z); // Adjust camera height
        camera.far = cameraZ * 4; // Increase the camera's far clipping plane to cover the whole model
        camera.lookAt(center);
        camera.updateProjectionMatrix();

        // Adjust renderer size to fit the loaded model
        const canvasWidth = mountRef.current?.clientWidth || window.innerWidth;
        const canvasHeight = mountRef.current?.clientWidth || window.innerWidth;
        renderer.setSize(canvasWidth, canvasHeight);
      });

      // Add resize listener
      window.addEventListener("resize", onWindowResize, false);
    };

    const onWindowResize = () => {
      const canvasWidth = mountRef.current?.clientWidth || window.innerWidth;
      const canvasHeight = mountRef.current?.clientWidth || window.innerWidth;
      camera.aspect = canvasWidth / canvasHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(canvasWidth, canvasHeight);
    };

    const animate = () => {
      requestAnimationFrame(animate);

      // Rotation and bouncing effect
      if (model) {
        // Rotation
        rotationX += rotationDirectionX * rotationSpeed;
        rotationY += rotationDirectionY * rotationSpeed;
        model.rotation.x = rotationX;
        model.rotation.y = rotationY;
        if (Math.abs(rotationX) >= rotationLimit) {
          rotationDirectionX *= -1;
        }
        if (Math.abs(rotationY) >= rotationLimit) {
          rotationDirectionY *= -1;
        }

        // Bouncing effect
        model.position.y = Math.sin(Date.now() * 0.001) * 5; // Increased bounce effect
      }

      renderer.render(scene, camera);
    };

    init();
    animate();

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [filePath]);

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className="threeDOBJECT flex justify-center items-center"
      ref={mountRef}
    />
  );
};

export default ThreeScene;