/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./blog.scss";
import Pages from "../links/links";

export default function BlogCarousel() {
  const [posts, setPosts] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    axios
      .get(
        "https://public-api.wordpress.com/rest/v1/sites/flawlessproductions9.wordpress.com/posts"
      )
      .then((res) => {
        setPosts(res.data.posts);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleNext = () => {
    setCurrentSlide(
      (prevCurrentSlide) => (prevCurrentSlide + 1) % posts.length
    );
  };

  const handlePrevious = () => {
    setCurrentSlide(
      (prevCurrentSlide) => (prevCurrentSlide - 1 + posts.length) % posts.length
    );
  };

  return (
    <div className="relative h-full overflow-hidden Dolce text-white mt-20 ">
      <hr className="text-white bg-white mix-blend-difference"></hr>
      <h1 className="py-4 px-12 text-left">News // Blog</h1>
      <div className="relative h-96 overflow-hidden Dolce text-white">
        <div className="absolute top-0 left-0 w-full h-full px-12">
          <div className="relative h-full">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="relative w-full h-full">
                {posts.map((post: any, index: number) => {
                  return (
                    <div
                      className={`absolute inset-0 transition-all duration-500 ease-in-out ${
                        index === currentSlide ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <Link to={`${Pages.blog}/${posts[currentSlide].ID}`} aria-label={post.title}>
                        <div className="w-1/2 h-96 absolute left-0 top-0">
                          <img
                            className="object-cover object-center bg-center h-full w-full"
                            src={post.featured_image}
                            alt={post.title}
                          />
                        </div>
                        <div className="absolute w-1/2 top-0 right-0 pl-10 h-96 z-0 flex flex-col items-baseline">
                          <h2 className="text-white text-xl font-medium text-left">
                            {post.title}
                          </h2>
                          <div
                            className="text-left text-xs h-auto overflow-hidden pt-2 blog-content"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "flex",
                              flexDirection: "column",
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                            dangerouslySetInnerHTML={{ __html: post.content }}
                          />

                          <div style={{ textAlign: "left" }}>
                            <button
                              className="text-white mt-auto text-left view-article"
                              style={{
                                position: "absolute",
                                bottom: 0,
                                width: "fit-content",
                              }}
                            >
                              // View Article
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 w-fit px-12 flex ml-auto gap-6 page-number z-10 prev-next">
        <button className="text-white" onClick={handlePrevious}>
          &lt;
        </button>
        <p className="text-white">
          {currentSlide + 1}/{posts.length}
        </p>
        <button className="text-white" onClick={handleNext}>
          &gt;
        </button>
      </div>
    </div>
  );
}
