import React, { useEffect, useRef, useState } from "react";
import "./cabinet.scss";
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import Pages from "../../components/links/links";

const blackBox = {
  initial: {
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
  },
  animate: {
    width: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    width: "100vw",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const webLinks = [
  {
    id: 1,
    link: Pages.poppys,
    name: "Poppy's ice cream",
    description: "Ibrahim Shady",
    image: "https://i.imgur.com/EALLOgm.png",
    category: "Design",
  },
  {
    id: 2,
    link: Pages.fest,
    name: "Fest",
    description: "Ibrahim Shady, Abdelrahman Nashed",
    image: "https://i.imgur.com/r0r6p4W.png",
    category: "Development",
    category2: "Design",
  },
  {
    id: 3,
    link: "https://nurburgring-gallery.netlify.app/",
    name: "Nurburgring Top 10 Gallery",
    description: "Ibrahim Shady",
    image: "https://i.imgur.com/XQzLNHr.png",
    category: "Development",
  },
  {
    id: 4,
    link: "https://squaredevelopments.netlify.app/",
    name: "Load-In Animation",
    description: "Ibrahim Shady",
    image: "https://i.imgur.com/2joSu2i.png",
    category: "Development",
  },
];

const Cabinet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imgRef = useRef<HTMLImageElement>(null);

  const handleMouseMove = (e: MouseEvent) => {
    const { clientX, clientY } = e;
    const { left, top, width, height } = document.body.getBoundingClientRect();

    const x = clientX - left;
    const y = clientY - top;

    const xPercent = x / width;
    const yPercent = y / height;

    imgRef.current.style.transform = `translate(-${xPercent * 75}px, -${
      yPercent * 75
    }px)`;
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    // Add a listener to re-check the media query when the viewport changes
    const onChange = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", onChange);

    // Remove the listener when the component unmounts
    return () => mediaQuery.removeEventListener("change", onChange);
  }, []);

  const [activeCategory, setActiveCategory] = React.useState("All");

  const [hoveredLink, setHoveredLink] = React.useState(null);

  return (
    <div className="bg-white overflow-x-hidden h-full Dolce">
      <motion.div
        className="fixed z-50 w-full bg-orange-600"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={blackBox}
      />
      {isMobile ? (
        // mobile
        <div>
          <div className="h-screen w-full px-4" id="cabinetHome">
            <div className="absolute top-64 right-4 z-0 pr-4 rotate-6 div-moving-img">
              <img
                className="moving-img"
                src="https://i.imgflip.com/2tgoec.jpg?a465504"
                alt="imaginitive concepts"
              />
            </div>
            <div className="Dolce mix-blend-difference z-10 text-white flex items-end h-full md:w-3/4 w-1/2 pointer-events-none">
              <h5 className="text-4xl pb-8 cabinet-title pl-8 sm:pl-0">
                A place where our creative minds keep all their innovative and
                imaginative concepts
              </h5>
            </div>
          </div>

          <div>
            <div className="flex flex-col">
              <h1 className="text-2xl px-12 pt-20">
                Developers' utensils 🍽️
                <br />& Designers' Cofee ☕
              </h1>
              <div className="text-[0.8rem] leading-3 items-end px-12 flex gap-2 py-2">
                <button
                  className={`border border-black rounded-full px-4 py-1 hover:bg-orange-600 hover:text-white  ${
                    activeCategory === "All" ? "bg-orange-600 text-white" : ""
                  }`}
                  onClick={() => setActiveCategory("All")}
                >
                  All
                </button>
                <button
                  className={`border border-black rounded-full px-4 py-1 hover:bg-orange-600 hover:text-white ${
                    activeCategory === "Development"
                      ? "bg-orange-600 text-white"
                      : ""
                  }`}
                  onClick={() => setActiveCategory("Development")}
                >
                  Development
                </button>
                <button
                  className={`border border-black rounded-full px-4 py-1 hover:bg-orange-600 hover:text-white ${
                    activeCategory === "Design"
                      ? "bg-orange-600 text-white"
                      : ""
                  }`}
                  onClick={() => setActiveCategory("Design")}
                >
                  Design
                </button>
              </div>
            </div>
            <hr className="border-black opacity-50" />
            <div className="grid">
              {webLinks
                .filter((link) =>
                  activeCategory === "All"
                    ? true
                    : link.category === activeCategory ||
                      link.category2 === activeCategory
                )
                .map((link) => (
                  <div key={link.id} className="link Dolce font-black">
                    {link.category === "Design" ||
                    link.category2 === "Design" ? (
                      <div key={link.id} className="link Dolce">
                        <Link
                          to={link.link}
                          className="relative block h-full w-full text-black z-20 py-2"
                        >
                          <div className="flex flex-row h-full items-center px-12 ">
                            <img
                              src={link.image}
                              alt={link.name}
                              className="w-24 pr-4 inline-block aspect-video"
                            />
                            <div className="flex flex-col">
                              <h1 className="text-xl"> {link.name}</h1>
                              <div className="text-xs flex flex-col items-start leading-3 opacity-50 gap-1">
                                <h1>{link.description}</h1>
                                <div className="flex flex-row gap-1">
                                  <h1 className="border border-black rounded-full px-2">
                                    {link.category}
                                  </h1>
                                  {link.category2 ? (
                                    <h1 className="border border-black rounded-full px-2">
                                      {link.category2}
                                    </h1>
                                  ) : (
                                    <span className="hidden"></span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <hr className="border-black opacity-50" />
                      </div>
                    ) : (
                      <div key={link.id} className="link Dolce">
                        <a
                          href={link.link}
                          target="_blank"
                          className="relative block h-full w-full text-black z-20 py-2"
                        >
                          <div className="flex flex-row h-full items-center px-12 ">
                            <img
                              src={link.image}
                              alt={link.name}
                              className="w-24 pr-4 inline-block aspect-video"
                            />
                            <div className="flex flex-col">
                              <h1 className="text-xl"> {link.name}</h1>
                              <div className="text-xs flex flex-col items-start leading-3 opacity-50 gap-1">
                                <h1>{link.description}</h1>
                                <div className="flex flex-row gap-1">
                                  <h1 className="border border-black rounded-full px-2">
                                    {link.category}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <hr className="border-black opacity-50" />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        // laptops
        <div>
          <div className="h-screen w-full px-12" id="cabinetHome">
            <div className="absolute top-40 right-20 z-0 pr-12 rotate-6">
              <img
                ref={imgRef}
                className="moving-img"
                src="https://i.imgflip.com/2tgoec.jpg?a465504"
                alt="imaginitive concepts"
              />
            </div>
            <div className="Dolce mix-blend-difference z-10 text-white flex items-end h-full w-1/2 pointer-events-none">
              <h5 className="text-7xl creative-minds">
                A place where our creative minds keep all their innovative and
                imaginative concepts
              </h5>
            </div>
          </div>

          <div>
            <div className="flex flex-row pt-40 pb-4 px-12">
              <h1 className="text-5xl  ">
                Developers' utensils 🍽️
                <br />& Designers' Cofee ☕
              </h1>
              <div className="ml-auto text-[0.8rem] leading-3 items-end justify-center flex gap-2">
                <button
                  className={`border border-black rounded-full px-4 py-1 hover:bg-orange-600 hover:text-white  ${
                    activeCategory === "All" ? "bg-orange-600 text-white" : ""
                  }`}
                  onClick={() => setActiveCategory("All")}
                >
                  All
                </button>
                <button
                  className={`border border-black rounded-full px-4 py-1 hover:bg-orange-600 hover:text-white ${
                    activeCategory === "Development"
                      ? "bg-orange-600 text-white"
                      : ""
                  }`}
                  onClick={() => setActiveCategory("Development")}
                >
                  Development
                </button>
                <button
                  className={`border border-black rounded-full px-4 py-1 hover:bg-orange-600 hover:text-white ${
                    activeCategory === "Design"
                      ? "bg-orange-600 text-white"
                      : ""
                  }`}
                  onClick={() => setActiveCategory("Design")}
                >
                  Design
                </button>
              </div>
            </div>
            <hr className="border-black opacity-50" />
            <div className="grid">
              {webLinks
                .filter((link) =>
                  activeCategory === "All"
                    ? true
                    : link.category === activeCategory ||
                      link.category2 === activeCategory
                )
                .map((link) => (
                  <div key={link.id} className="link Dolce font-black">
                    {link.category === "Design" ||
                    link.category2 === "Design" ? (
                      <Link
                        to={link.link}
                        onMouseEnter={() => setHoveredLink(link.id)}
                        onMouseLeave={() => setHoveredLink(null)}
                        style={{
                          opacity: hoveredLink === link.id ? 1 : 0.5,
                          zIndex: hoveredLink === link.id ? 20 : 0,
                        }}
                        className="relative block h-24 w-full text-black z-20 transition-all ease-in-out duration-200"
                      >
                        <div className="flex flex-row h-full justify-center items-center px-12 ">
                          <h1 className="text-xl"> {link.name} </h1>
                          <div className="ml-auto text-[0.8rem] flex gap-2 items-center justify-center leading-3">
                            <h1>{link.description}</h1>
                            <h1 className="border border-black rounded-full px-4 py-1">
                              {link.category}
                            </h1>
                            {link.category2 ? (
                              <h1 className="border border-black rounded-full px-4 py-1">
                                {link.category2}
                              </h1>
                            ) : (
                              <span className="hidden"></span>
                            )}
                          </div>
                        </div>
                        {hoveredLink === link.id && (
                          <motion.div
                            animate={{
                              rotate: [-1, 1, -1, 1, -1, 1, -1, 1, 0],
                            }}
                            transition={{ duration: 10, yoyo: Infinity }}
                            className="absolute top-[-100%] left-96 h-full w-full pointer-events-none z-0"
                          >
                            <motion.img
                              src={link.image}
                              alt={link.name}
                              className="pointer-events-none object-cover w-[25rem] h-[17.5rem]"
                              animate={{ opacity: 1, x: hoveredLink ? 30 : 0 }}
                              transition={{ duration: 0.3 }}
                            ></motion.img>
                          </motion.div>
                        )}
                      </Link>
                    ) : (
                      <a
                        href={link.link}
                        target="_blank"
                        onMouseEnter={() => setHoveredLink(link.id)}
                        onMouseLeave={() => setHoveredLink(null)}
                        style={{
                          opacity: hoveredLink === link.id ? 1 : 0.5,
                          zIndex: hoveredLink === link.id ? 20 : 0,
                        }}
                        className="relative block h-24 w-full text-black z-20 transition-all ease-in-out duration-200"
                        rel="noreferrer"
                      >
                        {" "}
                        <div className="flex flex-row h-full justify-center items-center px-12 ">
                          <h1 className="text-xl"> {link.name} </h1>

                          <div className="ml-auto text-[0.8rem] flex gap-2 items-center justify-center leading-3">
                            <h1>{link.description}</h1>
                            <h1 className="border border-black rounded-full px-4 py-1">
                              {link.category}
                            </h1>
                          </div>
                        </div>
                        {hoveredLink === link.id && (
                          <motion.div
                            animate={{
                              rotate: [-1, 1, -1, 1, -1, 1, -1, 1, 0],
                            }}
                            transition={{ duration: 10, yoyo: Infinity }}
                            className="absolute top-[-100%] left-96 h-full w-full pointer-events-none"
                          >
                            <motion.img
                              src={link.image}
                              alt={link.name}
                              className="pointer-events-none object-cover w-[25rem] h-[17.5rem]"
                              animate={{ opacity: 1, x: hoveredLink ? 20 : 0 }}
                              transition={{ duration: 0.3 }}
                            ></motion.img>
                          </motion.div>
                        )}
                      </a>
                    )}

                    <hr
                      className="border-black"
                      style={{
                        opacity: hoveredLink === link.id ? 1 : 0.5,
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      <Footer></Footer>
    </div>
  );
};

export default Cabinet;
