import React, { useEffect } from "react";
import Footer from "../../../components/footer/footer";
import { motion } from "framer-motion";
import LazyLoad from "react-lazy-load";
import ThreeScene from "../../../components/3dObject/3dObject";
import mkVideo from "../../../videos/mk.mp4";

const blackBox = {
  initial: {
    height: "100vh",
    top: 0,
  },
  animate: {
    height: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    height: "100vh",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

function Mk() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const enterBottom = {
    offscreen: {
      opacity: 0,
    },
    inView: (custom) => ({
      opacity: 1,
      transition: {
        duration: 1,
        delay: 1 + custom * 0.2,
        ease: "easeInOut",
      },
    }),
  };

  const filePath = "/3d/mk.glb";

  return (
    <motion.div className="Dolce bg-[#e4e4e4] overflow-x-hidden">
      <motion.div
        className="fixed z-50 w-full bg-orange-600 Dolce text-6xl text-white"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={blackBox}
      ></motion.div>
      <div className="flex flex-row justify-center px-12 h-screen text-left items-end md:flex-col md:min-h-screen md:h-full">
        <motion.div
          variants={enterBottom}
          custom={0.3}
          initial="offscreen"
          animate="inView"
          viewport={{ once: true }}
          className="w-1/2 h-full flex justify-center items-center md:w-full md:max-h-[30rem]"
        >
          <ThreeScene filePath={filePath} />
        </motion.div>
        <div className="flex flex-col w-1/2 md:w-full">
          <motion.h1
            variants={enterBottom}
            custom={0.1}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="text-7xl leading-[0.8] 2xs:text-5xl 2xs:leading-[0.75]"
          >
            MK INSURANCE BROKER
          </motion.h1>
          <motion.h2
            variants={enterBottom}
            custom={0.2}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="text-xl py-8"
          >
            Your one stop shop for all your insurance needs, to present that we
            created a series of eye-catching posts and reels that showcase types
            of insurance and the importance of being prepared for life's
            surprises.{" "}
          </motion.h2>
          <div className="flex flex-row gap-4 items-center">
            <motion.a
              variants={enterBottom}
              custom={0.5}
              initial="offscreen"
              animate="inView"
              viewport={{ once: true }}
              className="text-lg md:text-sm xs:text-xs px-4 py-1 border border-black rounded-full hover:bg-orange-600 hover:text-white"
              href="https://www.instagram.com/mk_insurancebroker/"
              aria-label="Mk instagram"
            >
              <div className="flex flex-row gap-1 items-center">
                <span className="inline-block work-link">Instagram</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-7 h-7 inline-block"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </div>
            </motion.a>
            <motion.a
              variants={enterBottom}
              custom={0.5}
              initial="offscreen"
              animate="inView"
              viewport={{ once: true }}
              className="text-lg md:text-sm xs:text-xs px-4 py-1 border border-black rounded-full hover:bg-orange-600 hover:text-white"
              href="https://www.behance.net/gallery/171859949/MK-Insurance-Broker-Social-Media-Campaign"
              aria-label="Mk behance"
            >
              <div className="flex flex-row gap-1 items-center">
                <span className="inline-block work-link">
                  VIEW PROJECT ON BEHANCE
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-7 h-7 inline-block"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </div>
            </motion.a>
          </div>
          <motion.hr
            variants={enterBottom}
            initial="offscreen"
            animate="inView"
            custom={0.5}
            viewport={{ once: true }}
            className="border-black my-8"
          />
          <motion.div
            variants={enterBottom}
            initial="offscreen"
            animate="inView"
            custom={0.5}
            viewport={{ once: true }}
            className="flex flex-row justify-between text-sm pb-4"
          >
            <div className="flex flex-col">
              <h1 className="text-slate-900">ROLE</h1>
              <h2 className="text-slate-600">SOCIAL MEDIA</h2>
            </div>
            <div className="flex flex-col">
              <h1 className="text-slate-900">Duration</h1>
              <h2 className="text-slate-600">3/2023 - present</h2>
            </div>
            <div className="flex flex-col">
              <h1 className="text-slate-900">Year</h1>
              <h2 className="text-slate-600">2023</h2>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="px-12 ">
        <LazyLoad offset={500} height={"100%"} className="lazyload-container">
          <video
            disablePictureInPicture
            loop
            muted
            playsInline
            autoPlay
            src={mkVideo}
            className="w-full h-full"
          ></video>
        </LazyLoad>
        <div className="flex flex-col gap-2 pt-2 h-full w-full">
          <div className="grid grid-cols-3 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/gnmc1Ji.jpeg"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/4uGRN9Z.jpeg"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/ddH0OER.jpeg"
                alt="mk1"
              />
            </LazyLoad>
          </div>
          <div className="grid grid-cols-2 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/9X23TlO.png"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/0Snl1ri.png"
                alt="mk1"
              />
            </LazyLoad>
          </div>
          <div className="grid grid-cols-3 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/7Wtk7Gi.png"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/yUWQMyg.png"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/WwmIsBf.png"
                alt="mk1"
              />
            </LazyLoad>
          </div>
          <div className="grid grid-cols-2 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/Aw1UXsj.jpeg"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/LolG4Iu.jpeg"
                alt="mk1"
              />
            </LazyLoad>
          </div>
          <div className="grid grid-cols-3 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/YNpWJqM.jpeg"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/yeRIOkF.png"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/k6nDrej.jpeg"
                alt="mk1"
              />
            </LazyLoad>
          </div>
          <div className="grid grid-cols-3 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <video
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/aQkmAjL.mp4"
                disablePictureInPicture
                loop
                muted
                playsInline
                autoPlay
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/jdNJwel.jpg"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <video
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/agdQSRl.mp4"
                disablePictureInPicture
                loop
                muted
                playsInline
                autoPlay
              />
            </LazyLoad>
          </div>
          <div className="grid grid-cols-3 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/2YinxDf.jpeg"
                alt="MK1"
              />
            </LazyLoad>
            <LazyLoad>
              <video
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/1zTLvjh.mp4"
                disablePictureInPicture
                loop
                muted
                playsInline
                autoPlay
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/Pm95tt2.png"
                alt="mk1"
              />
            </LazyLoad>
          </div>
          <div className="grid grid-cols-2 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/tesZVGp.jpeg"
                alt="mk1"
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/cZ1jaw4.png"
                alt="mk1"
              />
            </LazyLoad>
          </div>
          <div className="grid grid-cols-3 gap-2 h-full w-full max-h-max">
            <LazyLoad>
              <video
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/aQkmAjL.mp4"
                disablePictureInPicture
                loop
                muted
                playsInline
                autoPlay
              />
            </LazyLoad>
            <LazyLoad>
              <img
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/yeRIOkF.png"
                alt="MK1"
              />
            </LazyLoad>
            <LazyLoad>
              <video
                className="w-full h-full square object-cover"
                width="1080"
                height="1080"
                src="https://i.imgur.com/W3UagLy.mp4"
                disablePictureInPicture
                loop
                muted
                playsInline
                autoPlay
              />
            </LazyLoad>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-full px-12 pt-8">
        <div>
          <h1 className="text-5xl">Project Team</h1>
        </div>
        <div className="w-full h-full flex flex-col md:pt-2 md:h-full">
          <div className="ml-auto w-1/2 flex flex-row justify-between text-sm pb-4 md:w-full">
            <div className="flex flex-col">
              <h1 className="text-slate-400">Content Creation</h1>
              <h2 className="text-slate-900">Karim Eltayeb</h2>
            </div>
            <div className="flex flex-col">
              <h1 className="text-slate-400">Design & Motion</h1>
              <h2 className="text-slate-900">Ibrahim Shady</h2>
            </div>
          </div>
          <div className="ml-auto w-1/2 flex flex-col gap-4 md:w-full ">
            <hr className="border-slate-400" />
            <div className="flex flex-row gap-2">
              <a
                className="text-sm sm:text-xs px-4 py-1 border border-black rounded-full hover:bg-orange-600 hover:text-white"
                href="https://www.instagram.com/mk_insurancebroker/"
                aria-label="Mk instagram"
              >
                <div className="flex flex-row gap-1 items-center">
                  <span className="inline-block work-link">
                    View full project on Instagram
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-2 h-2 inline-block"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </motion.div>
  );
}

export default Mk;
