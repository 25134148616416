/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "./Home.scss";
import Mission from "../../components/mission/mission";
import Tv from "../../components/tv/tv";
import Footer from "../../components/footer/footer";
import WorkComp from "../../components/work/work";
import Blog from "../../components/blog/blog";
import "./Media.scss";

const blackBox = {
  initial: {
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
  },
  animate: {
    height: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    height: "100vh",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

class Home extends React.Component<
  {},
  { scrolled: number; scrolledbar: number }
> {
  //scroll function
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0,
      scrolledbar: 0,
    };
    this.onScrollPage = this.onScrollPage.bind(this);
  }
  onScrollPage = () => {
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolledPercentage = `${(this.state.scrolled / winHeightPx) * 100}%`;
    this.setState({
      scrolledbar: parseFloat(scrolledPercentage),
    });
    this.setState({
      scrolled: document.documentElement.scrollTop,
    });

    if (this.state.scrolled >= 3000) {
      var AppBg = document.getElementById("App");
      AppBg!.style.background = "#030301";

      var ps = document.getElementById("ps");
      ps!.style.color = "#f6f4f3";

      var textColor = document.getElementsByClassName(
        "work-text"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < textColor.length; i++) {
        textColor[i].style.color = "#f6f4f3";
      }

      var borderColor = document.getElementsByClassName(
        "border-black"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < borderColor.length; i++) {
        borderColor[i].style.borderColor = "#f6f4f3";
      }

      var borderColor2 = document.getElementsByClassName(
        "border-white"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < borderColor2.length; i++) {
        borderColor2[i].style.borderColor = "#f6f4f3";
      }

      var textColor2 = document.getElementsByClassName(
        "text-white"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < textColor2.length; i++) {
        textColor2[i].style.color = "#f6f4f3";
      }

      var bgColor = document.getElementsByClassName(
        "bg-white"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < bgColor.length; i++) {
        bgColor[i].style.background = "#f6f4f3";
      }
    } else {
      var AppBg = document.getElementById("App");
      AppBg!.style.background = "#f6f4f3";

      var ps = document.getElementById("ps");
      ps!.style.color = "#030301";

      var textColor = document.getElementsByClassName(
        "work-text"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < textColor.length; i++) {
        textColor[i].style.color = "#030301";
      }

      var borderColor = document.getElementsByClassName(
        "border-black"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < borderColor.length; i++) {
        borderColor[i].style.borderColor = "#030301";
      }

      var borderColor2 = document.getElementsByClassName(
        "border-white"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < borderColor2.length; i++) {
        borderColor2[i].style.borderColor = "#030301";
      }

      var textColor2 = document.getElementsByClassName(
        "text-white"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < textColor2.length; i++) {
        textColor2[i].style.color = "#030301";
      }

      var bgColor = document.getElementsByClassName(
        "bg-white"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < bgColor.length; i++) {
        bgColor[i].style.background = "#030301";
      }
    }
  };

  //page loaded

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.onScrollPage);
  }
  //unload
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollPage);
  }
  render() {
    return (
      <div>
        <motion.div
          className="fixed z-50 w-full bg-orange-600"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={blackBox}
        />
        <div id="App" className="App">
          <Mission></Mission>
          <WorkComp></WorkComp>
          <Tv></Tv>
          <Blog></Blog>
          <hr className="text-white my-8 bg-white mix-blend-difference"></hr>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Home;
