import React from "react";
import "./mission.scss";
import { ReactComponent as Ace } from "../../SVG/Ace.svg";
import { motion } from "framer-motion";


function Mission() {
  const chars = {
    offscreen: {
      opacity: 0,
    },
    inView: (custom) => ({
      opacity: 1,
      transition: {
        duration: 1,
        delay: 1.5 + custom * 0.3,
        ease: "easeInOut",
      },
    }),
  };
  return (
    <div className="home">
      <div className="mission mb-8 md:mb-12">
        <motion.div
          className="text-sm w-64 Dolce text-left absolute right-96 md:right-auto md:top-[60vh] sm:top-auto sm:bottom-[10rem] sm:right-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 4 }}
        >
          DESIGN, develop, and suceed
        </motion.div>

        <motion.div
          className="text-sm w-64 Dolce text-left absolute right-12 md:top-[60vh] sm:top-auto sm:bottom-12 sm:right-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 4 }}
        >
          We are Flawless Productions - Your partner in digital success through
          Brand Design, Web Development and Marketing.
        </motion.div>
        <motion.span
          className="line"
          variants={chars}
          custom={0}
          initial="offscreen"
          whileInView="inView"
          viewport={{ once: true }}
        >
          bring
        </motion.span>
        <br />
        <motion.span
          variants={chars}
          custom={1}
          initial="offscreen"
          whileInView="inView"
          viewport={{ once: true }}
          className="line inline-block"
        >
          your br
          <span className="inline-block ar">
            <motion.span
              initial={{ opacity: 1 }}
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.5,
                repeatDelay: 2,
                ease: "easeInOut",
              }}
              className="a"
            >
              a
            </motion.span>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.5,
                repeatDelay: 2,
                ease: "easeInOut",
              }}
            >
              <Ace className="ace"></Ace>
            </motion.div>
          </span>
          nd
        </motion.span>
        <br />
        <motion.span
          variants={chars}
          custom={2}
          initial="offscreen"
          whileInView="inView"
          viewport={{ once: true }}
          className="line"
        >
          vision to life with
        </motion.span>
        <br />
        <motion.span
          variants={chars}
          custom={3}
          initial="offscreen"
          whileInView="inView"
          viewport={{ once: true }}
          className="line"
        >
          <span className="dirtyline">FLAWLESS</span>
          &nbsp;
          <span>Productions</span>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 4 }}
            className="line fadein"
          >
            *
          </motion.span>
        </motion.span>
      </div>
    </div>
  );
}

export default Mission;
