import React, { useEffect } from "react";
import Footer from "../../../components/footer/footer";
import { motion } from "framer-motion";
import LazyLoad from "react-lazy-load";
import fest1 from "../../../photos/fest1.webp";
import fest from "../../../videos/fest-sr.mp4";
import ThreeScene from "../../../components/3dObject/3dObject";

const blackBox = {
  initial: {
    height: "100vh",
    top: 0,
  },
  animate: {
    height: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    height: "100vh",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

function Mk() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const enterBottom = {
    offscreen: {
      opacity: 0,
    },
    inView: (custom) => ({
      opacity: 1,
      transition: {
        duration: 1,
        delay: 1 + custom * 0.2,
        ease: "easeInOut",
      },
    }),
  };

  const filePath = "/3d/fest.glb";

  return (
    <motion.div className="Dolce bg-[#e0e0e0] overflow-x-hidden">
      <motion.div
        className="fixed z-50 w-full bg-orange-600 Dolce text-6xl text-white"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={blackBox}
      ></motion.div>
      <div className="flex flex-row justify-center px-12 h-screen text-left items-end md:flex-col md:min-h-screen md:h-full">
        <motion.div
          variants={enterBottom}
          custom={0.3}
          initial="offscreen"
          animate="inView"
          viewport={{ once: true }}
          className="w-1/2 h-full flex justify-center items-center md:w-full md:max-h-[30rem]"
        >
          <ThreeScene filePath={filePath} />
        </motion.div>
        <div className="flex flex-col w-1/2 md:w-full">
          <motion.h1
            variants={enterBottom}
            custom={0.1}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="text-7xl leading-[0.8]"
          >
            FEST
          </motion.h1>
          <motion.h2
            variants={enterBottom}
            custom={0.2}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="text-xl py-8"
          >
            FEST, A concept project for a an international event organizer,
            where we created a brand identity and website
          </motion.h2>
          <div className="flex flex-row gap-4 ">
            <motion.a
              variants={enterBottom}
              custom={0.5}
              initial="offscreen"
              animate="inView"
              viewport={{ once: true }}
              className="text-sm px-4 py-1 border border-black rounded-full hover:bg-orange-600 hover:text-white"
              href="https://fest-fx.netlify.app/"
              target="_blank"
              aria-label="fest website"
            >
              <div className="flex flex-row gap-1 items-center">
                <span className="inline-block work-link">WEBSITE</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 inline-block"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </div>
            </motion.a>
          </div>
          <motion.hr
            variants={enterBottom}
            custom={1}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="border-black my-8"
          />
          <motion.div
            variants={enterBottom}
            custom={1}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="flex flex-row justify-between text-sm pb-4"
          >
            <div className="flex flex-col">
              <h1 className="text-slate-900">Project Type</h1>
              <h2 className="text-slate-600">
                BRAND IDENTITY & WEB DEVELOPMENT
              </h2>
            </div>
            <div className="flex flex-col">
              <h1 className="text-slate-900">Year</h1>
              <h2 className="text-slate-600">2023</h2>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="px-12 pb-16">
        <LazyLoad offset={100}>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1.5, duration: 1 } }}
            className="pt-6 w-full "
            src="https://i.imgur.com/ZClA27P.png"
            alt="Semsem"
          />
        </LazyLoad>
        <LazyLoad>
          <div className="grid grid-cols-4 gap-4 pt-4">
            <LazyLoad offset={100} height={"100%"}>
              <img
                src="https://i.imgur.com/39EWEez.png"
                alt="fest"
                className="w-full h-full"
              />
            </LazyLoad>
            <LazyLoad offset={100} height={"100%"}>
              <img
                src="https://i.imgur.com/QuRNTys.png"
                alt="fest"
                className="w-full h-full"
              />
            </LazyLoad>
            <LazyLoad offset={100} height={"100%"}>
              <img
                src="https://i.imgur.com/7fECWxb.png"
                alt="fest"
                className="w-full h-full"
              />
            </LazyLoad>
            <LazyLoad offset={100} height={"100%"}>
              <img
                src="https://i.imgur.com/L9zIceg.png"
                alt="fest"
                className="w-full h-full"
              />
            </LazyLoad>
          </div>
        </LazyLoad>
      </div>
      <div className="h-full px-12 flex justify-center items-center">
        <LazyLoad offset={100} height={"100%"}>
          <img className="h-full w-full" src={fest1} alt="brand identity" />
        </LazyLoad>
      </div>
      <div className="h-full px-12 flex justify-center items-center video-fest">
        <a
          href="https://fest-fx.netlify.app/"
          target="_blank"
          rel="noreferrer"
          aria-label="fest website"
        >
          <LazyLoad offset={100} height={"100%"}>
            <video
              disablePictureInPicture
              loop
              muted
              playsInline
              autoPlay
              src={fest}
              className="w-full h-[85vh] py-16 sm:h-[60vh]"
            ></video>
          </LazyLoad>
        </a>
      </div>
      <div className="flex flex-col w-full h-full px-12 pt-8">
        <div className="ml-auto w-1/2 md:w-full flex flex-col ">
          <h1 className="text-justify text-[1.85vw] leading-none after:content-[''] after:w-full after:inline-block md:text-left md:text-xl">
            any similarity with real life is purely coincidental.
          </h1>
          <div className="flex flex-row gap-2 mb-8">
            <a
              className="text-sm px-4 py-1 border border-black rounded-full hover:bg-orange-600 hover:text-white"
              href="https://fest-fx.netlify.app/"
              aria-label="fest link"
            >
              <div className="flex flex-row gap-1 items-center">
                <span className="inline-block work-link">Visit Website</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-2 h-2 inline-block"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </div>
            </a>
          </div>
          <hr className="border-black" />
        </div>
        <div className="w-full h-full flex flex-col pt-4">
          <div className="flex flex-col">
          <div className="ml-auto w-1/2 md:w-full flex flex-row justify-between text-sm pb-4">
            <div className="flex flex-col">
              <h1 className="text-black">Design</h1>
              <h2 className="text-slate-600">Abdelrahman Nashed</h2>
            </div>
            <div className="flex flex-col">
              <h1 className="text-black">Web Development</h1>
              <h2 className="text-slate-600">Ibrahim Shady</h2>
            </div>
          </div>
          <div className="ml-auto w-1/2 md:w-full flex flex-row justify-between text-sm pb-4">
            <div className="flex flex-col">
              <h1 className="text-black">Typography</h1>
              <h2 className="text-slate-600">Poppins</h2>
            </div>
          </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </motion.div>
  );
}

export default Mk;
