import React, { useEffect } from "react";
import Footer from "../../../components/footer/footer";
import { motion } from "framer-motion";
import LazyLoad from "react-lazy-load";
import ThreeScene from "../../../components/3dObject/3dObject";

const blackBox = {
  initial: {
    height: "100vh",
    top: 0,
  },
  animate: {
    height: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    height: "100vh",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

function Poppys() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const enterBottom = {
    offscreen: {
      opacity: 0,
    },
    inView: (custom) => ({
      opacity: 1,
      transition: {
        duration: 1,
        delay: 1 + custom * 0.2,
        ease: "easeInOut",
      },
    }),
  };

  const filePath = "/3d/poppy.glb";

  return (
    <motion.div className="Dolce bg-[#D6D6D6] overflow-x-hidden">
      <motion.div
        className="fixed z-50 w-full bg-orange-600 Dolce text-6xl text-white"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={blackBox}
      ></motion.div>
      <div className="flex flex-row justify-center px-12 h-screen text-left items-end md:flex-col md:min-h-screen md:h-full">
        <motion.div
          variants={enterBottom}
          custom={0.3}
          initial="offscreen"
          animate="inView"
          viewport={{ once: true }}
          className="w-1/2 h-full flex justify-center items-center md:w-full md:max-h-[30rem]"
        >
          <ThreeScene filePath={filePath} />
        </motion.div>
        <div className="flex flex-col w-1/2 md:w-full">
          <motion.h1
            variants={enterBottom}
            custom={0.1}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="text-7xl leading-[0.8]"
          >
            POPPY'S ICE CREAM
          </motion.h1>
          <motion.h2
            variants={enterBottom}
            custom={0.2}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="text-xl py-8"
          >
            Poppy's ice cream shop is more than just an ice cream shop; it's a
            time machine to the past, where laughter was abundant, worries were
            fleeting, and every moment was cherished.
          </motion.h2>
          <div className="flex flex-row gap-4 ">
            <motion.a
              variants={enterBottom}
              custom={0.5}
              initial="offscreen"
              animate="inView"
              viewport={{ once: true }}
              className="text-sm px-4 py-1 border border-black rounded-full hover:bg-orange-600 hover:text-white"
              href="https://www.behance.net/gallery/175599375/Poppys-Ice-Cream"
              target="_blank"
              aria-label="poppy's behance"
            >
              <div className="flex flex-row gap-1 items-center">
                <span className="inline-block">Behance</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 inline-block"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </div>
            </motion.a>
          </div>
          <motion.hr
            variants={enterBottom}
            custom={1}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="border-black my-8"
          />
          <motion.div
            variants={enterBottom}
            custom={1}
            initial="offscreen"
            animate="inView"
            viewport={{ once: true }}
            className="flex flex-row justify-between text-sm pb-4"
          >
            <div className="flex flex-col">
              <h1 className="text-slate-900">Project Type</h1>
              <h2 className="text-slate-600">BRAND IDENTITY</h2>
            </div>
            <div className="flex flex-col">
              <h1 className="text-slate-900">Year</h1>
              <h2 className="text-slate-600">2023</h2>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full px-12 py-20 md:py-12">
        <LazyLoad offset={100} height={"100%"}>
          <img
            src="https://i.imgur.com/LS59YjL.png"
            alt="poppys icecream"
            className="w-[65vw]"
          />
        </LazyLoad>
      </div>
      <div className="flex flex-col items-center justify-center w-full px-12 pb-20 md:pb-12">
        <LazyLoad offset={100} height={"100%"}>
          <img
            src="https://i.imgur.com/8ZcVqPV.png"
            alt="poppys icecream"
            className="w-[65vw]"
          />
        </LazyLoad>
      </div>
      <div className="flex flex-col items-center justify-center w-full px-12 pb-20 md:pb-12">
        <LazyLoad offset={100} height={"100%"}>
          <img
            src="https://i.imgur.com/EtogRoA.jpg"
            alt="poppys icecream"
            className="w-[65vw]"
          />
        </LazyLoad>
      </div>

      <div className="flex items-center justify-center w-full px-12 pb-20 md:pb-12">
        <LazyLoad offset={100} height={"100%"}>
          <img
            src="https://i.imgur.com/dCEBhLq.png"
            alt="poppys icecream"
            className="w-[65vw]"
          />
        </LazyLoad>
      </div>
      <div className="flex items-center justify-center w-full px-12 pb-20 md:pb-12">
        <LazyLoad offset={100} height={"100%"}>
          <img
            src="https://i.imgur.com/MT2SzsQ.gif"
            alt="poppys icecream"
            className="w-[65vw]"
          />
        </LazyLoad>
      </div>
      <div className="flex items-center justify-center w-full px-12 pb-20 md:pb-12">
        <LazyLoad offset={100} height={"100%"}>
          <img
            src="https://i.imgur.com/kAopOTt.jpg"
            alt="poppys icecream"
            className="w-[65vw]"
          />
        </LazyLoad>
      </div>
      <div className="flex items-center justify-center w-full px-12 pb-20 md:pb-12">
        <LazyLoad offset={100} height={"100%"}>
          <img
            src="https://i.imgur.com/YjHkvxR.jpg"
            alt="poppys icecream"
            className="w-[65vw]"
          />
        </LazyLoad>
      </div>
      <div className="flex flex-col w-full h-full px-12 pt-8">
        <div className="ml-auto w-1/2 md:w-full flex flex-col ">
          <h1 className="text-justify text-[1.85vw] leading-none after:content-[''] after:w-full after:inline-block md:text-left md:text-xl">
            any similarity with real life is purely coincidental.
          </h1>
          <div className="flex flex-row gap-2 mb-8">
            <a
              className="text-sm px-4 py-1 border border-black rounded-full hover:bg-orange-600 hover:text-white"
              href="https://www.behance.net/gallery/175599375/Poppys-Ice-Cream"
              aria-label="poppy's behance link"
            >
              <div className="flex flex-row gap-1 items-center">
                <span className="inline-block work-link">behance</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-2 h-2 inline-block"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </div>
            </a>
          </div>
          <hr className="border-black" />
        </div>
        <div className="w-full h-full flex flex-col pt-4">
          <div className="ml-auto w-1/2 md:w-full flex flex-row justify-between text-sm pb-4">
            <div className="flex flex-col">
              <h1 className="text-black">Design</h1>
              <h2 className="text-slate-600">Ibrahim Shady</h2>
            </div>
            <div className="flex flex-col">
              <h1 className="text-black">Typography</h1>
              <h2 className="text-slate-600">Bulletto Killa</h2>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </motion.div>
  );
}

export default Poppys;
